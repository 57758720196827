<template>
  <div v-if="passings">
    <div class="filters">
      <custom-checkbox
        label="Тест не пройден"
        :checked="isPassingFilter"
        v-model="isPassingFilter"
      />
    </div>

    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      :isExternalFilterPresent="isExternalFilterPresent"
      :doesExternalFilterPass="doesExternalFilterPass"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <table-pagination
      :activeFilter="activeFilter"
      :gridApi="gridApi"
      :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
      :countOnPage="countOnPage"
    />
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "../../../components/Tables/CellRenderers/EditRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import Preloader from "../../../components/Technical/Preloader.vue";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import TablePagination from "../../../components/OtherElements/TablePagination.vue";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import PassingLinkRenderer from "../../../components/Tables/CellRenderers/PassingLinkRenderer.vue";
import CustomCheckbox from "../../../components/Forms/Fields/CustomCheckbox.vue";
export default {
  name: "question-table",
  components: {
    VPagination,
    AgGridVue,
    EditRenderer,
    Preloader,
    TablePagination,
    PassingLinkRenderer,
    CustomSelectFilter,
    CustomTextFilter,
    CustomCheckbox,
  },
  setup() {
    const store = useStore(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      isPreloader = ref(false),
      activeFilter = ref(false);
    const statuses = {
      new: "Новый",
      payed: "Оплачен",
      instruction: "Инструкция",
      questionnaire: "Опросник",
      in_progress: "В процессе",
      finished: "Завершен",
    };
    const isPassingFilter = ref(false);
    const passings = computed(() => store.state.test.passings),
      rowData = computed(() => {
        let url = process.env.VUE_APP_FRONT;
        return passings.value.map((passing) => {
          return {
            id: passing.id,
            code: passing.code,
            status: statuses[passing.status],
            link: `${url}/passing/${passing.code}`,
            user: passing.questionnaire
              ? `${passing.questionnaire.last_name} ${passing.questionnaire.first_name} ${passing.questionnaire.patronymic}`
              : null,
            finished_at: passing.finished_at,
            report_type: reportTranslate[passing.report_type],
            allowed: passing.allow_view_report,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "user",
          headerName: "ФИО",
          wrapText: true,
          minWidth: 200,
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по имени",
          },
        },
        {
          field: "status",
          headerName: "Статус",
          width: 180,
          flex: 0,
          filter: "CustomSelectFilter",
          filterParams: {
            filterName: "Поиск по статусу",
            selectOptions: Object.values(statuses),
          },
        },
        {
          field: "finished_at",
          headerName: "Дата прохождения",
          width: 180,
          flex: 0,
        },
        {
          field: "report_type",
          headerName: "Тип отчета",
          width: 180,
          flex: 0,
          filter: "CustomSelectFilter",
          filterParams: {
            filterName: "Поиск по типу отчета",
            selectOptions: Object.values(reportTranslate),
          },
        },
        {
          field: "code",
          headerName: "Ссылка",
          wrapText: true,
          width: 240,
          flex: 0,
          cellRenderer: "PassingLinkRenderer",
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const isExternalFilterPresent = () => isPassingFilter.value;
    const doesExternalFilterPass = (node) =>
      node.data.status !== statuses["finished"];

    onBeforeMount(() => {
      store.dispatch("test/getPassings");
    });

    watch(isPassingFilter, () => {
      gridApi.value.onFilterChanged();
      activeFilter.value = !activeFilter.value;
    });

    return {
      countOnPage,
      isPreloader,
      passings,
      isPassingFilter,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      activeFilter,

      isExternalFilterPresent,
      doesExternalFilterPass,
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}
</style>
