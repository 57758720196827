<template>
  <div class="custom-text-filter">
    <div v-if="params.filterName" class="custom-text-filter__title">
      {{ params.filterName }}
    </div>
    <div>
      <custom-input
        name="custom-text-filter"
        :delay="400"
        :inputWidth="100"
        cleanable
        v-model="filterText"
        placeholder="Введите текст"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import CustomInput from "../../Forms/Fields/CustomInput.vue";
import { watch } from "@vue/runtime-core";
export default {
  components: { CustomInput },
  setup(props) {
    const filterText = ref(null);

    const doesFilterPass = (params) => {
      const { api, colDef, column, columnApi, context } = props.params;
      const { node } = params;

      let passed = true;
      filterText.value
        .toLowerCase()
        .split(" ")
        .forEach((filterWord) => {
          const value = props.params.valueGetter({
            api,
            colDef,
            column,
            columnApi,
            context,
            data: node.data,
            getValue: (field) => node.data[field],
            node,
          });

          if (
            !value ||
            value.toString().toLowerCase().indexOf(filterWord) < 0
          ) {
            passed = false;
          }
        });

      return passed;
    };

    const isFilterActive = () => {
      return filterText.value != null && filterText.value !== "";
    };

    const getModel = () => {
      if (!isFilterActive()) {
        return null;
      }

      return { value: filterText.value };
    };

    const setModel = (model) => {
      filterText.value = model == null ? null : model.value;
    };

    watch(filterText, () => {
      props.params.filterChangedCallback();
    });

    return {
      filterText,
      doesFilterPass,
      isFilterActive,
      getModel,
      setModel,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-text-filter {
  padding: 5px 5px 5px 20px;
  background-color: white;
  border: 1px solid var(--cream-color);
  border-radius: 3px;

  &__title {
    font-size: 15px;
    font-weight: 400;
    padding-top: 8px;
  }
}
</style>