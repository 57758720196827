<template>
  <div class="loadingio-spinner-cube-wi8rhu7i48">
    <div class="ldio-q8b5t4erqbn">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mini-preloader",
};
</script>

<style lang="css" scoped>
@keyframes ldio-q8b5t4erqbn {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.ldio-q8b5t4erqbn div {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 11.333333333333336px;
  left: 11.333333333333336px;
  background: #ffffff;
  animation: ldio-q8b5t4erqbn 0.48s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: -0.13043478260869565s;
}
.ldio-q8b5t4erqbn div:nth-child(2) {
  top: 11.333333333333336px;
  left: 55.66666666666667px;
  background: #cffae9;
  animation-delay: -0.08695652173913045s;
}
.ldio-q8b5t4erqbn div:nth-child(3) {
  top: 55.66666666666667px;
  left: 11.333333333333336px;
  background: #d4f5ca;
  animation-delay: 0s;
}
.ldio-q8b5t4erqbn div:nth-child(4) {
  top: 55.66666666666667px;
  left: 55.66666666666667px;
  background: #d2ffec;
  animation-delay: -0.04347826086956522s;
}
.loadingio-spinner-cube-wi8rhu7i48 {
  width: 38px;
  height: 38px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-q8b5t4erqbn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.38);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-q8b5t4erqbn div {
  box-sizing: content-box;
}
</style>